<template>
  <div v-if="getUser.role.slug === 'super-administrateur'">
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col cols="12" md="12" lg="12" class="mb-md-0 mb-2">
            <b-row>
              <b-col cols="12" sm="10" md="10" lg="10">
                <label for="service">Client</label>
                <v-select
                  v-model="payloadFilter.client_id"
                  :options="customerOptions"
                  class="invoice-filter-select"
                  label="name"
                  :reduce="(client) => client.id"
                >
                  <template v-slot:no-options>
                    <b-spinner
                      v-if="isCustomersWithoutPaginationLoading"
                      style="width: 2.5rem; height: 2.5rem"
                      class="align-middle text-info"
                    />
                    <span v-else class="mt-1 font-medium-1"
                      >Aucun client ne correspond</span
                    >
                  </template>
                </v-select>
              </b-col>
              <b-col cols="12" sm="6" md="2" lg="2" class="mt-2">
                <b-button variant="primary" @click="applyFilterClientByPortefeuilleAction()">
                  <div v-if="isFilterEmployeePayment">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>
                  <span v-else class="text-nowrap font-medium-1">Filtrer</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card title="Liste des clients optant pour le paiement par portefeuille">
      <b-row>
       
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-2"
        >
          <b-button variant="primary" @click="reLoadDataClientAction()">
            <div v-if="isReLoadDataClient">
              <span> Chargement ... </span>
              <b-spinner small />
            </div>
            <span v-else class="text-nowrap font-medium-1">Actualiser</span>
          </b-button>
        </b-col>
      </b-row>

      <vue-good-table
        :columns="columns"
        :rows="rows"
        :is-loading="isClientByPortefeuilleLoading"
      >
        <template slot="emptystate">
          <div class="text-center">
            <span> {{ isClientByPortefeuilleLoading ? "" : "Liste vide" }} </span>
          </div>
        </template>

        <template slot="loadingContent">
          <div class="text-center text-info">
            <b-spinner class="align-middle" /> &nbsp;
            <span class="align-middle">Chargement... </span>
          </div>
        </template>

        <template slot="table-row" slot-scope="props">
          <div
            v-if="props.column.field === 'full_name'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">{{
              props.row.user.full_name
            }}</span>
          </div>

          <div
            v-else-if="props.column.field === 'number_of_employee'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">
              {{ props.row.numbers_employees }}
            </span>
          </div>
          <div
            v-else-if="props.column.field === 'total_amount'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap text-info font-small-5">
              {{ props.row.total_budget }} FCFA
            </span>
          </div>
          <div
            v-else-if="props.column.field === 'date'"
            class="text-nowrap text-center align-middle"
          >
            <span class="d-block text-nowrap font-small-10">
              Chaque <span class="font-small-10 text-info  font-bold">{{ props.row.automatic_pay_day }}</span> du mois 
            </span>
          </div>
          <div
            v-else-if="props.column.field === 'status'"
            class="text-nowrap text-center align-middle"
          >
            <span
              
              v-if="props.row.automatic_pay_status == 2 && props.row.automatic_pay == 1"
            >
            <b-badge pill variant="light-success" class="text-capitalize font-medium-2">Validé</b-badge>    
            </span>
            <span
              
              v-else-if="props.row.automatic_pay == 0"
            >
            <b-badge pill variant="light-danger" class="text-capitalize font-medium-2">Désactivé</b-badge>    
            </span>
            <span
              v-else
            >
             <b-badge pill variant="light-warning" class="text-capitalize font-medium-2">En attente de validation</b-badge>    
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'action'"
            class="align-middle text-center text-info"
          >
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="20"
                    class="text-info align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur'"
                  :id="`details${props.row.id}`"
                  :disabled="props.row.automatic_pay_status == 2"
                  @click="applyAutomaticPaymentValidation(props.row)"
                >
                  <feather-icon
                    icon="CheckIcon"
                    class="cursor-pointer text-info mr-1"
                    size="25"
                  />
                  <span>Valider</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="getUser.role.slug === 'super-administrateur'"
                  :id="`details${props.row.id}`"
                  :disabled="props.row.automatic_pay_status == 2 && props.row.automatic_pay == 0"
                  @click="applyDesactivateAutomaticPayment(props.row)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer text-danger mr-1"
                    size="25"
                  />
                  <span>Désactiver</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </div>
        </template>
      </vue-good-table>

      <div class="d-flex justify-content-between mt-2 flex-wrap">
        <b-button
          :disabled="metaData.prev_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreClientByPortefeuilleAction(metaData.prev_page_url)"
        >
          <span class="text-white text-nowrap font-medium-2">Page précédente</span>
        </b-button>

        <div>
          <span class="text-white font-medium-2"
            >{{ metaData.current_page }} sur {{ metaData.last_page }} pages</span
          >
        </div>
        <div>
          <span class="text-white font-medium-2">Total : {{ total }}</span>
        </div>
        <b-button
          :disabled="metaData.next_page_url == null"
          variant="outline-secondary"
          @click="applyLoadMoreClientByPortefeuilleAction(metaData.next_page_url)"
        >
          <span class="text-nowrap font-medium-1 text-white">Page suivante</span>
        </b-button>
      </div>
    </b-card>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCardHeader,
  BCardBody,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";

import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
    vSelect,
    VueGoodTable,
  },
  data() {
    return {
      customerOptions: [],
      isReLoadDataClient: false,
      rows: [],
      payloadFilter: {
        client_id: "",
      },
      columns: [
        {
          label: "Client",
          field: "full_name",
          
        },
        {
          label: "Nombre d'employé",
          field: "number_of_employee",
        },
        {
          label: "Montant à envoyer",
          field: "total_amount",
        },
        {
          label: "Date d'envoi",
          field: "date",
        },
        {
          label: "Statut",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],

      isFilterEmployeePayment: false,
      avatarText,

    };
  },
  computed: {
    
    ...mapGetters(["isCustomersWithoutPaginationLoading", "isClientByPortefeuilleLoading"]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("customers", ["getCustomersWithoutPagination"]),
    ...mapGetters("orders", ["getClientByPortefeuille"]),

    metaData() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        current_page_url: "",
        last_page: "",
        total: "",
      };
      if (this.getClientByPortefeuille != null) {
        meta.prev_page_url = this.getClientByPortefeuille.prev_page_url;
        meta.next_page_url = this.getClientByPortefeuille.next_page_url;
        meta.current_page = this.getClientByPortefeuille.current_page;
        meta.last_page = this.getClientByPortefeuille.last_page;
        this.total = this.getClientByPortefeuille.total;
      }
      return meta;
    },
  },

  watch: {
    isCustomersWithoutPaginationLoading(val) {
      if (val === false) {
        this.getCustomersWithoutPagination.forEach((element) => {
          this.customerOptions.push({
            id: element.id,
            name: element.customer_full_name,
          });
        });
      }
    },
   
    getClientByPortefeuille(newValue, oldValue) {
      if (oldValue.length !== 0 && oldValue.length !== newValue.length) {
        this.rows = this.getClientByPortefeuille.data;
      }
    },
    isClientByPortefeuilleLoading(val) {
      if (val === false) {
        this.rows = this.getClientByPortefeuille.data;
      }
    },
   
    payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
            newValue[key] = "";
          }
        });
      },
      deep: true,
    },
  },

  mounted() {
    if (this.getCustomersWithoutPagination.length != 0) {
      this.getCustomersWithoutPagination.forEach(element => {
        this.customerOptions.push({
          id: element.id,
          name: element.customer_full_name,
        })
      })
    }
    
  },

  created() {
    console.log(this.getClientByPortefeuille)
    this.getClientByPortefeuille.data != null
      ? (this.rows = this.getClientByPortefeuille.data)
      : null;
  },

  methods: {
    
    ...mapActions("orders", ["getClientByPortefeuilleAction", "automaticPaymentValidationAction","desactivateAutomaticPaymentAction","filterClientByPortefeuilleAction","loadMoreClientByPortefeuilleAction"]),
    applyAutomaticPaymentValidation(payment){
      this.$swal
        .fire({
          title: "Validation de paiement automatique",
          text: "Êtes-vous sûr de vouloir valider ce paiement automatique ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: () =>
            this.automaticPaymentValidationAction(payment.id)
              .then((response) => {
                
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              }),
        })
      .then(response => {
        if(response.isConfirmed){
        console.log(response)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Succès',
              icon: 'CheckIcon',
              text: "Paiement automatique validé avec succès",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )
        this.reLoadDataClientAction()     
      }
      })
      .catch(error => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'danger',
              icon: 'AlertTriangleIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        ) 
      })
   

    },

    applyDesactivateAutomaticPayment(payment){
      this.$swal
        .fire({
          title: "Désactivation de paiement automatique",
          text: "Êtes-vous sûr de vouloir désactiver ce paiement automatique ?",
          icon: "warning",
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: "Non",
          allowOutsideClick: false,
          confirmButtonText: "Oui",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-outline-primary ml-1",
          },
          buttonsStyling: false,
          preConfirm: () =>
            this.desactivateAutomaticPaymentAction(payment.id)
              .then((response) => {
                
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`${error.response.data.message}`);
              }),
        })
      .then(response => {
        if(response.isConfirmed){
        console.log(response)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Succès',
              icon: 'CheckIcon',
              text: "Paiement automatique désactivé avec succès",
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          },
        )
        this.reLoadDataClientAction()     
      }
      })
      .catch(error => {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'danger',
              icon: 'AlertTriangleIcon',
              text: error.response.data.message,
              variant: 'danger',
            },
          },
          {
            position: 'top-center',
          },
        ) 
      })
   

    },

    applyFilterClientByPortefeuilleAction() {
      const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
      if (allIsNUl) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      } else {
        this.isFilterEmployeePayment = true;
        this.rows = [];
        this.filterClientByPortefeuilleAction(this.payloadFilter)
          .then((response) => {
            this.isFilterEmployeePayment = false;
            this.rows = this.getEmployeesWallet;
          })
          .catch((error) => {
            console.log(error);
            this.isFilterEmployeePayment = false;
          });
      }
    },
    reLoadDataClientAction() {
      this.isReLoadDataClient = true;
      this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", true);
      this.rows = [];
      this.payloadFilter = {
        client_id: ""
      }
      this.getClientByPortefeuilleAction()
        .then((response) => {
          console.log(this.rows)
          this.isReLoadDataClient = false;
          this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", false);
        })
        .catch((error) => {
          this.isReLoadDataClient = false;
          this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", false);
        });
    },

   
    applyLoadMoreClientByPortefeuilleAction(url) {
      this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", true);
      this.rows = [];
      this.loadMoreClientByPortefeuilleAction(url)
        .then(() => {
          this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", false);
        })
        .catch(() => {
          this.$store.commit("SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
