import { render, staticRenderFns } from "./ClientsPaymentPortefeuilleList.vue?vue&type=template&id=c15fd548&scoped=true&"
import script from "./ClientsPaymentPortefeuilleList.vue?vue&type=script&lang=js&"
export * from "./ClientsPaymentPortefeuilleList.vue?vue&type=script&lang=js&"
import style0 from "./ClientsPaymentPortefeuilleList.vue?vue&type=style&index=0&id=c15fd548&lang=scss&scoped=true&"
import style1 from "./ClientsPaymentPortefeuilleList.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c15fd548",
  null
  
)

export default component.exports